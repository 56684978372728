import { useEffect, useState } from 'react';
import { Box, Heading, Tooltip, Flex, IconButton, Spacer, useToast, useDisclosure, 
    Menu, MenuButton, MenuList, MenuItem, MenuDivider } from '@chakra-ui/react';
import { AiOutlineStar } from 'react-icons/ai'
import { TiDeleteOutline } from 'react-icons/ti'
import { BiPaste } from 'react-icons/bi'
import { BsChevronDown, BsChevronRight } from 'react-icons/bs'
import { FiCopy } from 'react-icons/fi'
import { SavePresetDialog } from './SavePresetDialog';
import { useAuth } from 'contexts/AuthContext';
import { getEnvVariable } from 'utils/env';
import { usePresets } from 'contexts/PresetsContext';

/**
 * Renders a property header component.
 *
 * @component
 * @param {string} [label="Default Label"] - The label for the property header.
 * @param {*} props.value - The value of the property.
 * @param {Function} onChange - The function to call when the value changes.
 * @param {Array} [extras=[]] - Additional icons to display in the header.
 * @param {boolean} [clipboard=true] - Whether to display copy and paste icons.
 * @param {*} type - The type of the property.
 * @param {boolean} [collapsed=false] - Whether the header is collapsed.
 * @param {boolean} [collapsible=false] - Whether the header is collapsible.
 * @param {Function} onExpand - The function to call when the header is expanded.
 * @param {Function} onCollapse - The function to call when the header is collapsed.
 * @returns {JSX.Element} The rendered PropertyHeader component.
 */
export const PropertyHeader = ({
    label = "Default Label",
    value,
    onChange,
    extras = [],
    clipboard = true,
    type,
    collapsed,
    collapsible = false,
    onExpand,
    onCollapse
}) => {
    let icons = [...extras]
    const { token } = useAuth()
    const toast = useToast()
    const { isOpen: isPresetOpen, onOpen: onPresetOpen, onClose: onPresetClose } = useDisclosure()
    const REACT_APP_api_url = getEnvVariable("REACT_APP_api_url", process.env.REACT_APP_api_url)
    const { items: allItems, refresh, fetching: presetsFetching } = usePresets();
    const items = allItems.filter(p => p.type === type);

    const [fetching, setFetching] = useState(presetsFetching)

    const savePreset = () => {
        onPresetOpen()
    }

    useEffect(() => {
        setFetching(presetsFetching)
    }, [presetsFetching])

    const deletePreset = (name) => {
        setFetching(true)
        fetch(`${REACT_APP_api_url}/deletepreset`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ name, type, value }),
        }).then(response => {
            setFetching(false)
            return response.json()
        }).then(d => {
            toast({
                title: `Preset "${name}" deleted.`
            })
            refresh()
        })
    }

    const loadPreset = (name) => {
        setFetching(true)
        fetch(`${REACT_APP_api_url}/loadpreset`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ name, type, value }),
        }).then(response => {
            setFetching(false)
            return response.json()
        }).then(d => {
            onChange && onChange(d)
        })
    }

    if (clipboard) {
        // Copy
        icons.push(<Tooltip key="copy" hasArrow label="Copy">
            <IconButton variant={"ghost"} onClick={e => {
                e.stopPropagation()
                // Copy
                let v = JSON.parse(JSON.stringify(value))
                navigator.clipboard.writeText(JSON.stringify(v, null, 2)).then(
                    () => {
                        toast({ title: `Copied to clipboard.` })
                    }, () => {
                        /* clipboard write failed */
                    }
                );
            }}
                icon={<FiCopy />}
            />
        </Tooltip>)

        // Paste
        icons.push(<Tooltip key="paste" hasArrow label="Paste">
            <IconButton variant={"ghost"} onClick={e => {
                e.stopPropagation()
                // Paste
                navigator.clipboard.readText().then(text => {
                    try {
                        let newValue = JSON.parse(text)
                        onChange && onChange(newValue)
                        toast({
                            title: `Pasted from clipboard.`,
                            status: "success"
                        })
                    } catch (e) {
                        console.error(e)
                        toast({
                            title: `Paste Failed`,
                            description: "Make sure your clipboard is right",
                            status: "error"
                        })
                    }
                });
            }}
                icon={<BiPaste />}
            />
        </Tooltip>)
        // Presets
        if (type !== undefined) icons.push(<Tooltip key="presets">
            {/* Wrapper box required to suppress console spam (https://github.com/chakra-ui/chakra-ui/issues/3440#issuecomment-851707911) */}
            <Box>
                <Menu>
                    <Tooltip hasArrow label="Presets">
                        <MenuButton isLoading={fetching} icon={<AiOutlineStar />} as={IconButton} variant={"ghost"} />
                    </Tooltip>
                    <MenuList maxHeight="15rem" overflowY="auto">
                        <MenuItem onClick={savePreset}>Save as Preset</MenuItem>
                        {items.length > 0 && <>
                            <MenuDivider />
                            {items.map((item) => (
                                <MenuItem key={item.name} value={item.name} onClick={e => { loadPreset(item.name) }}><Flex alignContent={"center"} w={"full"}>{item.name}<Spacer />
                                    <Tooltip hasArrow label="Delete">
                                        <IconButton onClick={(e) => {
                                            e.stopPropagation()
                                            deletePreset(item.name)
                                        }} icon={<TiDeleteOutline />} style={{ margin: "0px" }} variant={"ghost"} />
                                    </Tooltip>
                                </Flex></MenuItem>
                            ))}
                        </>}
                    </MenuList>
                </Menu>
            </Box>
        </Tooltip>)
    }
    return <>
        <SavePresetDialog type={type} value={value} isOpen={isPresetOpen} onClose={onPresetClose} onSave={refresh} />
        <Flex w="full" direction="column" wrap="wrap">
            <Flex direction={"row"} wrap={"wrap"} alignItems={"center"}>
                {collapsible && <IconButton fontSize={"lg"} icon={collapsed ? <BsChevronRight /> : <BsChevronDown />} variant={"ghost"}
                        onClick={e => {
                            !collapsed && onCollapse && onCollapse()
                            collapsed && onExpand && onExpand()
                        }} />}
                <Heading fontSize={"lg"}>{label}</Heading>
                <Spacer />
                <Flex wrap="wrap" alignItems={"center"}>
                    <Spacer />
                    {icons.map(icon => icon)}
                </Flex>
            </Flex>
        </Flex>
    </>
}