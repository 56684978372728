import {
    Box, MenuButton, Menu, MenuList, MenuDivider, MenuOptionGroup, MenuItemOption,
    IconButton, Tooltip
} from '@chakra-ui/react'

import { BsStars } from 'react-icons/bs'

/**
 * Renders a menu with extra features for workshop.
 * @param {Object} value - The current value of the features.
 * @param {string} variant - The variant of the menu button.
 * @param {Function} onChange - The function to call when the value of the features changes.
 * @returns {JSX.Element} The rendered ExtrasPicker component.
 */
export const ExtrasPicker = ({
    architecture="stable-diffusion",
    value,
    variant,
    onChange = () => console.warn("No ExtrasPicker onChange handler.")
}) => {
    let features = []
    if(architecture === "stable-diffusion") {
        if (value.upscaler.enabled) features.push("upscaler")
        if (value.faceDetailer.enabled) features.push("faceDetailer")
        if (value.controlnet.enabled) features.push("controlnet_enabled")
        if (value.ipadapter.enabled) features.push("ipadapter_enabled")
        if (value.faceRestore.enabled) features.push("faceRestore")
        if (value.roop.enabled) features.push("roop_enabled")
    }

    const updateValue = function (v) {
        features = []
        let upscaler = false
        let faceDetailer = false
        let controlnet_enabled = false
        let ipadapter_enabled = false
        let faceRestore = false
        let roop_enabled = false

        v.forEach(f => {
            if (f === "upscaler") upscaler = true
            if (f === "faceDetailer") faceDetailer = true
            if (f === "faceRestore") faceRestore = true
            if (f === "controlnet_enabled") controlnet_enabled = true
            if (f === "ipadapter_enabled") ipadapter_enabled = true
            if (f === "roop_enabled") roop_enabled = true
        })

        onChange({
            ...value,
            controlnet: { ...value.controlnet, enabled: controlnet_enabled },
            ipadapter: { ...value.ipadapter, enabled: ipadapter_enabled },
            faceRestore: { ...value.faceRestore, enabled: faceRestore },
            roop: { ...value.roop, enabled: roop_enabled },
            upscaler: { ...value.upscaler, enabled: upscaler },
            faceDetailer: { ...value.faceDetailer, enabled: faceDetailer }
        })
    }

    // Wrapper box required to suppress console spam (https://github.com/chakra-ui/chakra-ui/issues/3440#issuecomment-851707911)
    return <Box>
        <Menu>
            <Tooltip hasArrow label="Extra Features...">
                <MenuButton
                    variant={variant}
                    icon={<BsStars />}
                    colorScheme={"purple"}
                    as={IconButton}
                />
            </Tooltip>
            <MenuList>
                <MenuOptionGroup value={features} title='Extra Features' type='checkbox' onChange={v => { updateValue(v) }}>
                    <MenuItemOption closeOnSelect={false} value='upscaler'>Upscaler</MenuItemOption>
                    <MenuItemOption closeOnSelect={false} value='controlnet_enabled'>ControlNet</MenuItemOption>
                    <MenuItemOption closeOnSelect={false} value='ipadapter_enabled'>IP-Adapter</MenuItemOption>
                    <MenuDivider />
                    <MenuItemOption closeOnSelect={false} value='faceDetailer'>Face Detailer</MenuItemOption>
                    <MenuItemOption closeOnSelect={false} value='faceRestore'>Face Restore</MenuItemOption>
                    <MenuItemOption closeOnSelect={false} value='roop_enabled'>Roop</MenuItemOption>
                </MenuOptionGroup>
            </MenuList>
        </Menu>
    </Box>
}