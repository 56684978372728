import React from 'react';
import { Box, Image as ChakraImage, useToast } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { useAuth } from 'contexts/AuthContext';
import { InfinityLightboxControls } from './InfinityLightboxControls';
import { getEnvVariable } from 'utils/env';

/**
 * Renders an InfinityLightbox component.
 *
 * @param {Object} props - The component props.
 * @param {Array} props.items - The list of items to display in the lightbox.
 * @param {Object} props.selectedPiece - The currently selected item in the lightbox.
 * @param {string} props.source - The source of the lightbox.
 * @param {string} [props.mode="preview"] - The mode of the lightbox.
 * @param {Function} [props.onCloseHandler=() => { console.warn("No onCloseHandler for InfinityLightbox") }] - The callback function to handle the close event.
 * @param {Function} [props.onMoved=() => { console.warn("No onMoved callback for InfinityLightbox") }] - The callback function to handle the move event.
 * @param {Function} [props.onEnd=() => { console.warn("No onEnd callback for InfinityLightbox") }] - The callback function to handle the end event.
 * @param {Function} [props.onStart=() => { console.warn("No onStart callback for InfinityLightbox") }] - The callback function to handle the start event.
 * @param {Function} [props.onSelectHandler=() => { console.warn("No onSelectHandler for InfinityLightbox") }] - The callback function to handle the select event.
 * @returns {JSX.Element} The rendered InfinityLightbox component.
 */
export function InfinityLightbox({
  items,
  selectedPiece,
  source,
  mode = "preview",
  onCloseHandler = () => { console.warn("No onCloseHandler for InfinityLightbox") },
  onMoved = () => { console.warn("No onMoved callback for InfinityLightbox") },
  onEnd = () => { console.warn("No onEnd callback for InfinityLightbox") },
  onStart = () => { console.warn("No onStart callback for InfinityLightbox") },
  onSelectHandler = () => { console.warn("No onSelectHandler for InfinityLightbox") },
  onCreate = () => { console.warn("No onCreate callback for InfinityLightbox") }
}) {
 
  const REACT_APP_api_url = getEnvVariable("REACT_APP_api_url", process.env.REACT_APP_api_url)
  const REACT_APP_images_url = getEnvVariable("REACT_APP_images_url", process.env.REACT_APP_images_url)

  const { token, user, permissions } = useAuth();
  const toast = useToast()
  const [isMax, setIsMax] = useState(false)
  const [isMoving, setIsMoving] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const handleRight = () => {
    setIsLoading(true)
    items.forEach((item, index) => {
      if (item._id === selectedPiece._id) {
        let i = index + 1
        if (i > items.length -1) {
          onEnd()
        }else{
          onSelectHandler(items[i])
        }
      }
    })
    setIsLoading(false)
  }

  const handleLeft = () => {
    setIsLoading(true)
    items.forEach((item, index) => {
      if (item._id === selectedPiece._id) {
        let i = index - 1
        if (i < 0) {
          i = items.length - 1
          onStart()
        }
        onSelectHandler(items[i])
      }
    })
    setIsLoading(false)
  }

  const handleKeyDown = (e) => {
    e.stopPropagation()
    if (!selectedPiece) return
    if (e.key === 'Escape') onCloseHandler()
    if (e.key === ']' || e.key === 'ArrowRight') handleRight()
    if (e.key === '[' || e.key === 'ArrowLeft') handleLeft()
    if ((permissions.includes("admin") || (user && user.sub === selectedPiece.author)) && e.key === 'Delete') handleDelete()
    if (user && user.sub === selectedPiece.author && e.key === 'p' && permissions.includes("supporter")) handleMove("personal_pieces")
    if (user && user.sub === selectedPiece.author && e.key === 's') handleMove("pieces")
  }
  const handleMove = (target) => {
    setIsMoving(true)
    let method = "POST"
    fetch(
      `${REACT_APP_api_url}/move/${target}/${selectedPiece._id}`, {
      method: method,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({}),
    }).then(d => {
      onMoved(selectedPiece._id)
      setIsMoving(false)
    })
  }
  const handleDelete = () => {
    // e.stopPropagation()
    setIsMoving(true)
    let method = "POST"
    let url = `${REACT_APP_api_url}/delete/${selectedPiece._id}`
    fetch(url, {
      method,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    }).then(d => {
      onMoved(selectedPiece._id)
      setIsMoving(false)
    })
  }

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    // window.addEventListener('touchstart', handleTouchStart);
    // window.addEventListener('touchmove', handleTouchMove);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      if (isMobile) {
        // window.removeEventListener('touchstart', handleTouchStart);
        // window.removeEventListener('touchmove', handleTouchMove);
      }
    }
  });
  let imgStyle = {
    maxWidth: "100%",
    maxHeight: "100%"
  }
  if (isMoving || isLoading) {
    imgStyle["filter"] = "blur(50px)"
  }
  // alert("InfinityLightbox.js: selectedPiece: " + JSON.stringify(selectedPiece))
  return <>{selectedPiece && <Box
    onKeyDown={handleKeyDown}
    onClick={onCloseHandler}
    style={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      backdropFilter: "blur(50px)",
      WebkitBackdropFilter: "blur(50px)",
      display: "flex",
      alignItems: !isMax ? 'center' : 'center',
      justifyContent: !isMax ? 'center' : 'center',
      zIndex: 1000,
    }}
  >
    {/* Image */}
    <div style={{ width: "100%", height: "100%", overflow: "auto", alignItems: "center", display: "flex" }}>
      <ChakraImage onClick={e => {
        e.stopPropagation()
        setIsMax(!isMax)
      }} style={!isMax ? { maxWidth: '100%', maxHeight: '100%', margin: "auto" } : { margin: "auto" }} src={(selectedPiece.status === "complete" && selectedPiece.location && selectedPiece.location.jpg) ? `${REACT_APP_images_url}/${selectedPiece.location.jpg}` : '/images/queued.gif'} />
    </div>
    {/* Controls */}
  </Box>}
    <InfinityLightboxControls selectedPiece={selectedPiece} mode={mode} source={source} onDelete={handleDelete} onMove={handleMove} isMoving={isMoving} onCloseHandler={onCloseHandler} onCreate={onCreate}/>
  </>
}
