import {
    Badge, Image, Box, Text, Flex, Tooltip, IconButton, ButtonGroup, useDisclosure, useToast,
    Slider, SliderTrack, SliderFilledTrack, SliderThumb, FormLabel, Wrap, Spinner
} from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { getEnvVariable } from "utils/env"
import { AiOutlineCopy, AiOutlineDelete, AiOutlineEdit, AiOutlineInfoCircle } from 'react-icons/ai'
import { ModalModelInfo } from "./ModalModelInfo"
import { ModalModelBrowser } from "./ModalModelBrowser"

export default function ModelButton({ id, modelArchitecture, onChange, onDelete,
    weight = 1.0,
    strength_model = 1.0,
    strength_clip = 1.0,
    modifyWeight = false,
    onClipStrengthChange,
    onModelStrengthChange,
    onWeightChange }) {
    const toast = useToast()
    const [data, setData] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { isOpen: isBrowserOpen, onOpen: onBrowserOpen, onClose: onBrowserClose } = useDisclosure()

    const REACT_APP_api_url = getEnvVariable("REACT_APP_api_url", process.env.REACT_APP_api_url)
    const REACT_APP_model_images_url = getEnvVariable("REACT_APP_model_images_url", process.env.REACT_APP_model_images_url)

    useEffect(() => {
        setIsLoading(true)
        fetch(`${REACT_APP_api_url}/v3/modelhash/${id}`)
            .then(response => response.json())
            .then(d => {
                // console.log(d)
                setData(d)
                setIsLoading(false)
            })
            .catch(e => {
                console.error(e)
                setIsLoading(false)
            })
    }, [id, REACT_APP_api_url, setData])

    return (
        <>
            <ModalModelInfo isOpen={isOpen} onClose={onClose} hash={id} />
            {data && <ModalModelBrowser
                modelType={[data.type]} hide={{ sort: true, modelTypes: true, modelArchitecture: true }}
                onSelect={id => {
                    onChange && onChange(id)
                    onBrowserClose()
                }} isOpen={isBrowserOpen} onClose={onBrowserClose} modelArchitecture={[modelArchitecture]} />
            }
            <Box rounded={"md"} borderWidth={1} width={{ base: '100%' }} alignItems={"start"} p={2}>
                <Flex direction="row" align="start">
                    {isLoading && <Box flex={1} ml={2}><Spinner size="sm" mr={2} />Loading...</Box>}
                    {!isLoading && data && data._id && <Image borderRadius={5} h={"96px"} w={"96px"} src={`${REACT_APP_model_images_url}/models/${id}.preview.jpg`} flexShrink={0} />}
                    {!isLoading && <Box flex={1} ml={2}>
                        {!data && <Text fontSize="md">❓ Unknown Model</Text>}
                        <Text fontSize="md">{data && data.name}</Text>
                        <Text fontSize="sm">{data && data.version}</Text>
                        <Wrap>
                            {data && data.nsfw && <Badge colorScheme="red">NSFW</Badge>}
                        </Wrap>
                        <ButtonGroup size="xs" variant="ghost">
                            {data && data._id && <Tooltip hasArrow label="Model Details" openDelay={250}>
                                <IconButton icon={<AiOutlineInfoCircle />} onClick={e => {
                                    onOpen()
                                }} />
                            </Tooltip>}
                            {onChange && <Tooltip hasArrow label="Change Model..." openDelay={250}>
                                <IconButton icon={<AiOutlineEdit />} onClick={e => {
                                    onBrowserOpen()
                                }} />
                            </Tooltip>}
                            <Tooltip hasArrow label="Copy Model ID" openDelay={250}>
                                <IconButton icon={<AiOutlineCopy />} onClick={() => { navigator.clipboard.writeText((data && data._id) ? data._id : id) }} />
                            </Tooltip>
                            {onDelete && <Tooltip hasArrow label="Delete Model" openDelay={250}>
                                <IconButton icon={<AiOutlineDelete />} onClick={onDelete} />
                            </Tooltip>}
                        </ButtonGroup>
                        {data && data.model_metadata && data.model_metadata.modelVersions && data.model_metadata.modelVersions.trainedWords && data.model_metadata.modelVersions.trainedWords.map(trainedWord => <Badge key={trainedWord} style={{ cursor: "pointer" }} onClick={e => {
                            if (data.type === "embedding") trainedWord = `<${trainedWord}>`
                            navigator.clipboard.writeText(trainedWord)
                            toast({
                                title: "Trained Word copied",
                                description: `Paste the trained word in your prompt to activate the ${data.type}`,
                                status: "success"
                            })
                        }}>
                            {trainedWord}
                        </Badge>)}
                        {modifyWeight && <>
                            {/* <Tooltip hasArrow label="Weight" openDelay={250}>
                                <FormLabel htmlFor="lora_weight">Weight ({weight})</FormLabel>
                            </Tooltip>
                            <Slider
                                id='weight'
                                value={weight}
                                step={0.05}
                                min={0}
                                max={1.5}
                                onChange={v => onWeightChange(v)}
                            >
                                <SliderTrack>
                                    <SliderFilledTrack />
                                </SliderTrack>
                                <SliderThumb />
                            </Slider> */}

                            <Tooltip hasArrow label="Model Strength" openDelay={250}>
                                <FormLabel htmlFor="lora_strength_model">Model Strength ({strength_model})</FormLabel>
                            </Tooltip>
                            <Slider
                                id='lora_strength_model'
                                value={strength_model}
                                step={0.05}
                                min={0}
                                max={1.5}
                                onChange={v => onModelStrengthChange(v)}
                            >
                                <SliderTrack>
                                    <SliderFilledTrack />
                                </SliderTrack>
                                <SliderThumb />
                            </Slider>

                            <Tooltip hasArrow label="CLIP Strength" openDelay={250}>
                                <FormLabel htmlFor="lora_strength_clip">CLIP Strength ({strength_clip})</FormLabel>
                            </Tooltip>
                            <Slider
                                id='lora_strength_clip'
                                value={strength_clip}
                                step={0.05}
                                min={0}
                                max={1.5}
                                onChange={v => onClipStrengthChange(v)}
                            >
                                <SliderTrack>
                                    <SliderFilledTrack />
                                </SliderTrack>
                                <SliderThumb />
                            </Slider>
                        </>}
                    </Box>}
                </Flex>
            </Box>
        </>
    )
}
