import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useAuth } from 'contexts/AuthContext'
import { Spinner, Center, Box } from '@chakra-ui/react'
// import { useNavigate} from 'react-router-dom'

import { InfinityPreview } from './InfinityPreview'
import { InfinityLightbox } from './InfinityLightbox'
import { getEnvVariable } from 'utils/env'

/**
 * Renders an infinity feed component.
 *
 * @param {string} path - The API path for fetching items.
 * @param {string} method - The method for fetching items.
 * @param {string} mode - The mode of the infinity feed.
 * @param {string} [source="pieces"] - The source of the items.
 * @returns {JSX.Element} The rendered InfinityFeed component.
 */
function InfinityFeed({
  onClick,
  onCreate,
  path,
  method,
  mode,
  source = "pieces",
  filters = {}
}) {
  const { isAuthenticated, token } = useAuth()
  const [items, setItems] = useState([])
  const [isLightboxOpen, setIsLightboxOpen] = useState(false)
  const [selectedPiece, setSelectedPiece] = useState(null)
  const [lastPiece, setLastPiece] = useState(null)
  const [apiPath, setApiPath] = useState("")
  const [activeImages, setActiveImages] = useState({})
  const sentinelRef = useRef(null)
  const infinityGalleryRef = useRef(null)
  const [fetching, setFetching] = useState(false)
  const [hasMore, setHasMore] = useState(true)

  const REACT_APP_api_url = getEnvVariable("REACT_APP_api_url", process.env.REACT_APP_api_url)

  let apiURL = `${REACT_APP_api_url}${path}`
  if (method === "random") {
    apiURL = `${REACT_APP_api_url}${path}`
  } else if (method === "continuation") {
    if (lastPiece) {
      apiURL = `${REACT_APP_api_url}${path}/50/${lastPiece._id}`
    } else {
      apiURL = `${REACT_APP_api_url}${path}/50`
    }
  } else {
    // apiURL = `${REACT_APP_api_url}${path}/50`
  }
  const observerCallback = (entries, observer) => {
    entries.forEach(entry => {
      const imgId = entry.target.dataset.id

      if (entry.isIntersecting) {
        setActiveImages(prevState => ({ ...prevState, [imgId]: true }))
      } else {
        setActiveImages(prevState => {
          const newState = { ...prevState }
          delete newState[imgId]
          return newState
        })
      }
    })
  }

  useEffect(() => {
    const imageNodes = document.querySelectorAll('.infinitygallery > div')
    const imageObserver = new IntersectionObserver(observerCallback)
    imageNodes.forEach(node => imageObserver.observe(node))
    return () => imageNodes.forEach(node => imageObserver.unobserve(node))
  }, [items])

  const fetchNewItems = useCallback(function () {
    let headers = {
      "Content-Type": "application/json"
    }
    if (isAuthenticated) {
      headers = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    } else {
      // console.log("Not logged in")
    }
    console.log(`Fetching from ${apiURL}`)
    console.log(filters)
    return fetch(apiURL, { method: "POST", headers, body: JSON.stringify({ filters }) })
      .then(response => response.json())
      .then(data => data)
  }, [apiURL, isAuthenticated, token, filters])

  const openLightbox = (piece) => {
    setIsLightboxOpen(true)
    setSelectedPiece(piece)
  }

  const closeLightbox = () => {
    setIsLightboxOpen(false)
    setSelectedPiece(null)
  }

  const handleMoved = (e) => {
    let newItems = JSON.parse(JSON.stringify(items))
    newItems.forEach((item, index) => {
      if (item._id === e) {
        newItems.splice(index, 1)
        setItems(newItems)
        if (selectedPiece && selectedPiece._id === e) {
          setSelectedPiece(items[index + 1])
        }
      }
    })
  }

  useEffect(() => {
    if (apiPath !== path) {
      setApiPath(path)
      setItems([])
      setHasMore(true)
      setLastPiece(undefined)
      fetchNewItems()
    }
  }, [apiPath, fetchNewItems, path])

  useEffect(() => {
    setItems([])
    setHasMore(true)
  }, [])

  const fetchMoreItems = useCallback(() => {
    setFetching(true)
    return fetchNewItems().then(newItems => {
      if (newItems.length === 0) {
        setHasMore(false)
      } else {
        setItems(prevItems => [...prevItems, ...newItems.filter(newItem => !prevItems.some(prevItem => prevItem._id === newItem._id))])
        setLastPiece(newItems[newItems.length - 1])
      }
      setFetching(false)
    }).catch(err => {
      console.log(err)
      setFetching(false)
    })
  }, [fetchNewItems])

  useEffect(() => {
    const sr = sentinelRef.current
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.target === sentinelRef.current && entry.isIntersecting && !fetching && hasMore) {
          fetchMoreItems().then(() => { })
        }
      })
    })
    observer.observe(sentinelRef.current)
    return () => observer.unobserve(sr)
  }, [fetching, hasMore, apiPath, fetchMoreItems])

  return (
    <>
      {isLightboxOpen && <InfinityLightbox selectedPiece={selectedPiece} source={source} items={items}
        onMoved={handleMoved}
        onEnd={fetchMoreItems}
        onCloseHandler={e => { closeLightbox() }}
        onSelectHandler={item => { setSelectedPiece(JSON.parse(JSON.stringify(item))) }}
        onCreate={onCreate}
      />}

      <Box className='infinitygallery' ref={infinityGalleryRef}>
        {items.map(piece => {
          let pieceClass = "square"
          return <div
            style={{ height: `250px` }}
            className={`${pieceClass} fade-in ${activeImages[piece._id] ? "visible" : "unset"}`}
            data-id={piece._id} key={piece._id}>
            {activeImages[piece._id] && <InfinityPreview
              mode={mode} piece={piece} source={source} key={piece._id}
              onMoved={handleMoved}
              onClick={onClick ? onClick : openLightbox}
              onCreate={onCreate}
            />}
            {!activeImages[piece._id] && <Box pos="relative" borderRadius="lg" bg={"blue.800"} />}
          </div>
        })}
      </Box>
      <Box ref={sentinelRef} style={{ width: "100%" }}>
        <Center p={5}>{fetching && <Spinner label='Loading more...' />}</Center>
        {!hasMore && <Center p={5}>No more items</Center>}
      </Box>
    </>
  )
}

export default InfinityFeed
