import React, { createContext, useState, useEffect, useContext } from 'react';
import { useAuth } from 'contexts/AuthContext';
import { getEnvVariable } from 'utils/env';

const PresetsContext = createContext();

export const usePresets = () => {
    return useContext(PresetsContext);
};

export const PresetsProvider = ({ children }) => {
    const { token } = useAuth();
    const [fetching, setFetching] = useState(false);
    const [items, setItems] = useState([]);
    const [refreshIndex, setRefreshIndex] = useState(0);
    
    const refresh = () => {
        setRefreshIndex(i => i + 1);
      };

    useEffect(() => {
        if (token) {
            const REACT_APP_api_url = getEnvVariable("REACT_APP_api_url", process.env.REACT_APP_api_url);
            setFetching(true);
            fetch(`${REACT_APP_api_url}/mypresets`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({})
            })
                .then((response) => response.json())
                .then(data => {

                    setItems(data);
                    setFetching(false);
                });
        }else{
            setItems([]);
            setFetching(false);
        }

    }, [token, refreshIndex]);

    const value = {
        items,
        fetching,
        refresh
    };

    return (
        <PresetsContext.Provider value={value}>
            {children}
        </PresetsContext.Provider>
    );
};