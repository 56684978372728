import { useEffect, useState, useCallback } from 'react';
import {
    Wrap, Tooltip, IconButton, useToast, useDisclosure, Progress, 
} from '@chakra-ui/react';
import { useAuth } from 'contexts/AuthContext';
import { BiLayerPlus } from 'react-icons/bi';
import { PropBox } from './PropBox';
import { getEnvVariable } from 'utils/env';
import { ModalModelBrowser } from 'components/Models/ModalModelBrowser';
import ModelButton from 'components/Models/ModelButton';

/**
 * Renders the LoraLayerOptions component.
 * 
 * @param {Object} props - The component props.
 * @param {Object} props.value - The value object.
 * @param {string} props.key - The key string.
 * @param {Function} props.onChange - The onChange function.
 * @param {Function} props.onDelete - The onDelete function.
 * @returns {JSX.Element} The rendered LoraLayerOptions component.
 */

/**
 * Renders the LoraOptions component.
 * 
 * @param {Object} props - The component props.
 * @param {Array} props.value - The selected Lora options.
 * @param {string} props.modeltype - The type of model (Lora or Embedding).
 * @param {Object} props.pipeline - The pipeline object.
 * @param {Function} props.onChange - The onChange event handler.
 * 
 * @returns {JSX.Element} The rendered LoraOptions component.
 */
export const LoraOptions = ({ 
    value,
    modeltype,
    pipeline,
    onChange = () => { console.warn("No onChange handler found.") },
}) => {
    const token = useAuth().token
    const toast = useToast()
    const [myLoras, setMyLoras] = useState([])
    const [filteredLoras, setFilteredLoras] = useState([])
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [extras, setExtras] = useState([])
    const [fetching, setFetching] = useState(true)
    const [filter, setFilter] = useState("")

    const REACT_APP_api_url = getEnvVariable("REACT_APP_api_url", process.env.REACT_APP_api_url)

    let url = `${REACT_APP_api_url}/v3/my_models/LORA`
    let heading = "LORA Options"
    let modelType = "Lora"

    if (modeltype === "embedding") {
        url = `${REACT_APP_api_url}/v3/my_models/TextualInversion`
        heading = "Textual Inversion Options"
        modelType = "Embedding"
    }

    const fetchLoras = useCallback(() => {
        setFetching(true)
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ type: pipeline.type })
        }).then((response) => {
            return response.json()
        }).then(actualdata => {
            let model_hashes = {}
            let models = []
            actualdata.forEach(model => {
                if (!model_hashes[model.hash]) {
                    models.push(model)
                    model_hashes[model.hash] = true
                }
            })
            setMyLoras(models)
            setFetching(false)
        })
    }, [pipeline.type, token, url])

    useEffect(fetchLoras, [token, url, pipeline.type, fetchLoras])

    const deleteHandler = useCallback(hash => {
        setFetching(true)
        fetch(`${REACT_APP_api_url}/v3/delete_model/${hash}`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({}),
        }).then(response => {
            setFetching(false)
            return response.json()
        }).then(d => {
            toast({
                title: `Removed`
            })
            fetchLoras()
        })
    }, [fetchLoras, toast, token, REACT_APP_api_url])

    useEffect(() => {
        if (!fetching) {
            setExtras([
                <Tooltip key="addLayer2" openDelay={250} hasArrow label="Add Layer...">
                    <IconButton variant={"ghost"} icon={<BiLayerPlus />} onClick={e=>{onOpen()}}/>
                </Tooltip>,
            ])
        }
    }, [fetching, setExtras, filter, filteredLoras, myLoras, onChange, onOpen, deleteHandler, value])

    useEffect(() => {
        setFilteredLoras(myLoras.filter((value, index, loras) => {
            if (!value.meta) return true
            if (value.meta.name.toLowerCase().indexOf(filter.toLowerCase()) > -1) return true
            return false
        }))
    }, [filter, myLoras])

    const handleSelect = hash=>{
        let newLoras = value || []
        let selectedLora = null
        newLoras.forEach(lora => {
            if (lora.hash === hash) selectedLora = lora
        })
        if (selectedLora !== null) return
        newLoras.push({
            hash: hash,
            weight: 1,
            strength_model: 1.00,
            strength_clip: 1.00
        })
        onChange(newLoras)
    }

    return <PropBox collapsible={false} type={modelType} label={heading} value={value} onChange={onChange} extras={extras}>
        <ModalModelBrowser onSelect={handleSelect} isOpen={isOpen} onClose={onClose} modelType={["LORA"]} modelArchitecture={[pipeline.type]} hide={{sort: true, modelTypes:true, modelArchitecture:true}}/>
        <Wrap w={"full"}>
            {fetching && <Progress size='xs' isIndeterminate w={"full"} />}
        </Wrap>
        {value && value.length > 0 && value.map((lora, index) => {
            return <ModelButton modelArchitecture={pipeline.type} id={lora.hash} key={lora.hash}
            strength_model={lora.strength_model?lora.strength_model:lora.weight}
            strength_clip={lora.strength_clip?lora.strength_clip:lora.weight}
            weight={lora.weight}
            modifyWeight={true} 
            onModelStrengthChange={v => {
                let newValue = [...value]
                newValue[index]["strength_model"] = v
                onChange(newValue)
            }}
            onClipStrengthChange={v => {
                let newValue = [...value]
                newValue[index]["strength_clip"] = v
                onChange(newValue)
            }}
            onChange={v => {
                let newValue = [...value]
                newValue[index]["hash"] = v
                onChange(newValue)
            }}
            onDelete={() => {
                let newValue = [...value]
                newValue.splice(index, 1)
                onChange(newValue)
            }}
            />
            // return <LoraLayerOptions value={lora} key={lora.hash} modeltype={modeltype}
            //     onDelete={() => {
            //         let newValue = [...value]
            //         newValue.splice(index, 1)
            //         onChange(newValue)
            //     }}
            //     onChange={v => {
            //         let newValue = [...value]
            //         newValue[index] = v
            //         onChange(newValue)
            //     }} />
        })}
    </PropBox>
}