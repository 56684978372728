import {
    Box, Switch, FormLabel, Tooltip, Flex, Wrap,
    NumberIncrementStepper, NumberDecrementStepper, NumberInput, NumberInputStepper, NumberInputField
} from '@chakra-ui/react';

import { LoraOptions } from './LoraOptions';
import { PipelinePicker } from './PipelinePicker';
import { PropBox } from './PropBox';
import { FreeUOptions } from './FreeUOptions';
import ModelButton from 'components/Models/ModelButton';

/**
 * Renders the ModelOptions component.
 * 
 * @param {Object} props - The component props.
 * @param {Object} props.value - The current value of the ModelOptions.
 * @param {Function} props.onChange - The function to handle value changes.
 * @returns {JSX.Element} The rendered ModelOptions component.
 */
export const ModelOptions = ({ value, onChange }) => {

    return value ? <PropBox type="model_pipeline" label="Model Pipeline" value={value} onChange={v => onChange && onChange(v)}>
        <PipelinePicker value={value.pipeline} onChange={v => onChange && onChange({ ...value, "pipeline": v })} />
        <Wrap>
            <Tooltip hasArrow label="Each model has been trained or fine-tuned on different sets of training data.  This can give each model significantly different aesthetic looks.  Press the model details button next to the dropdown to view additional details about your selected model." openDelay={250} >
                <FormLabel>Model</FormLabel>
            </Tooltip>
            <Flex w={"full"}>
                <Tooltip hasArrow label="Browse Models" openDelay={250}>
                    <ModelButton modelArchitecture={value.pipeline.type} onChange={v=>{onChange && onChange({...value, sd_model_checkpoint:v})}} id={value.sd_model_checkpoint}/>
                </Tooltip>
                {/*  */}
            </Flex>
        </Wrap>
        {/* clip_skip */}
        <Flex mr={2}>
            <Tooltip hasArrow label="Clip Skip" openDelay={250}>
                <FormLabel whiteSpace={"nowrap"} htmlFor="clip_skip">Clip Skip</FormLabel>
            </Tooltip>
            <NumberInput
                size={"xs"}
                id="clip_skip"
                value={value.clip_skip}
                min={1}
                max={8}
                step={1}
                clampValueOnBlur={true}
                onChange={v => {
                    onChange && onChange({ ...value, "clip_skip": v })
                }}
            >
                <NumberInputField />
                <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                </NumberInputStepper>
            </NumberInput>
        </Flex>
        <Flex>
            <Tooltip hasArrow label="Enable FreeU" openDelay={250} >
                <FormLabel htmlFor="freeu_enabled">Enable FreeU</FormLabel>
            </Tooltip>
            <Switch
                size={"sm"}
                id="freeu_enabled"
                isChecked={value.freeU.enabled}
                onChange={e => onChange && onChange({ ...value, freeU: { ...value.freeU, enabled: e.target.checked } })}
            />
        </Flex>
        {value.freeU.enabled && <FreeUOptions value={value.freeU} onChange={freeU => { onChange && onChange({ ...value, freeU }) }} />}
        <Flex>
            <Tooltip hasArrow label="Enable LORAs" openDelay={250}>
                <FormLabel htmlFor="loras_enabled">Enable LORAs</FormLabel>
            </Tooltip>
            <Switch
                size={"sm"}
                id="loras_enabled"
                isChecked={value.loras_enabled}
                onChange={e => onChange && onChange({ ...value, "loras_enabled": e.target.checked })}
            />
        </Flex>
        {value.loras_enabled && <LoraOptions modeltype="lora" value={value.loras} pipeline={value.pipeline} onChange={loras => { onChange && onChange({ ...value, loras }) }} />}
        <Flex>
            <Tooltip hasArrow label="Enable Textual Inversion embeddings" openDelay={250}>
                <FormLabel htmlFor="ti_enabled">Enable Textual Inversion</FormLabel>
            </Tooltip>
            <Switch
                id="ti_enabled"
                isChecked={value.ti_enabled}
                onChange={e => onChange && onChange({ ...value, "ti_enabled": e.target.checked })}
            />
        </Flex>
        {value.ti_enabled && <LoraOptions modeltype="embedding" value={value.embeddings} pipeline={value.pipeline} onChange={embeddings => { onChange && onChange({ ...value, embeddings }) }} />}
    </PropBox> : <Box>Loading...</Box>
}