/**
 * WorkshopPropertiesToolbar component displays a toolbar with various options for a workshop.
 * 
 * @component
 * @param {Object} data - The data object containing workshop properties.
 * @param {Function} generateHandler - The generateHandler function.
 * @param {Function} onGenerate - The onGenerate function.
 * @param {boolean} isGenerating - A boolean indicating whether the workshop is currently generating.
 * @param {boolean} isLoaded - A boolean indicating whether the workshop data is loaded.
 * @param {Function} onChange - The onChange function.
 * @returns {JSX.Element} The rendered WorkshopPropertiesToolbar component.
 */

import { Tooltip, IconButton, Spacer, ButtonGroup, Progress } from '@chakra-ui/react';

import { ExtrasPicker } from './ExtrasPicker';
import { GeneratePopover } from './GeneratePopover';

/**
 * WorkshopPropertiesToolbar component.
 * @param {Object} data - The data object.
 * @param {Function} generateHandler - The generateHandler function.
 * @param {Function} onGenerate - The onGenerate function.
 * @param {boolean} isGenerating - Indicates if generation is in progress.
 * @param {boolean} isLoaded - Indicates if data is loaded.
 * @param {Function} onChange - The onChange function.
 * @returns {JSX.Element} The WorkshopPropertiesToolbar component.
 */
export const WorkshopPropertiesToolbar = ({ 
  architecture="stable-diffusion",
  data, 
  isGenerating = false,
  isLoaded = true,
  onChange = () => { console.warn("No onChange handler for WorkshopPropertiesToolbar.") },
  generateHandler = () => { console.warn("No generateHandler for WorkshopPropertiesToolbar.") }
}) => {
    const variant = "ghost"

    const updateData = function (obj) {
        onChange && onChange(obj)
    }

    return isLoaded ? (
      <ButtonGroup w={'full'}>
        <ExtrasPicker
          architecture={architecture}
          variant={variant}
          // marginLeft={'45px'}
          width={'30px'}
          value={data.params}
          onChange={(v) => {
            updateData({ ...data, params: v });
          }}
        />
        <Spacer />
        <GeneratePopover data={data} isGenerating={isGenerating} onClick={generateHandler} onChange={onChange}/>
      </ButtonGroup>
    ) : (
      <ButtonGroup w={'full'} alignItems={'center'}>
        <IconButton disabled variant={'unstyled'} />
        <Tooltip
          hasArrow
          label={`Loading...`}
        >
          <Progress size="xs" isIndeterminate flex={1} />
        </Tooltip>
        <IconButton disabled variant={'unstyled'} />
      </ButtonGroup>
    );
}