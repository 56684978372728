import { Box, Image } from '@chakra-ui/react';
import { PreviewOverlay } from './PreviewOverlay';
import { useState } from 'react';
import { getEnvVariable } from 'utils/env';
import { ModalWorkshop } from 'components/Workshop/ModalWorkshop';

/**
 * Renders an InfinityPreview component.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.piece - The piece object.
 * @param {string} [props.mode="preview"] - The mode of the component.
 * @param {string} props.source - The source of the component.
 * @param {Function} [props.onClick=() => { console.warn("No onClick handler for InfinityPreview") }] - The onClick event handler.
 * @param {Function} [props.onMoved=() => { console.warn("No onMoved handler for InfinityPreview") }] - The onMoved event handler.
 * @returns {JSX.Element} The rendered InfinityPreview component.
 */
export function InfinityPreview({
  piece,
  mode = "preview",
  source,
  onCreate,
  onClick = () => { console.warn("No onClick handler for InfinityPreview") },
  onMoved = () => { console.warn("No onMoved handler for InfinityPreview") },
}) {
  const [isInterested, setIsInterested] = useState(false);
  const [modalOpen, setModalOpen] = useState(false)
  
  const REACT_APP_images_url = getEnvVariable("REACT_APP_images_url", process.env.REACT_APP_images_url)

  function touchover() {
    setIsInterested(true)
    window.setTimeout(() => {
      setIsInterested(false)
    }, 4000)
  }
  function over() {
    setIsInterested(true)
  }
  function out() {
    setIsInterested(false)
  }
  
  
  let thumbnail = `${REACT_APP_images_url}/${piece.location.jpg}`
  try{
    thumbnail = `${REACT_APP_images_url}/${piece.location.thumbs["512"]}`
  }catch(e){
    console.warn(`No 512px thumbnail for ${piece._id}`)
  }

  return (
    <Box
      className="infinitypreview"
      borderRadius={"lg"}
      style={{ overflow: "hidden" }}
      pos="relative"
      onTouchStart={touchover}
      onMouseOver={over}
      onMouseOut={out}
    >
      {/* Blur image */}
      <Image pos="absolute"
        src={thumbnail}
        alt={piece._id}
        style={{ objectFit: "cover", top: "0", left: "0", width: "100%", height: "100%" }}
        borderRadius="xl"
      />
      {/* Blur layer */}
      <Box
        style={{ position: "absolute", top: "0", left: "0", width: "100%", height: "100%", backdropFilter: "blur(20px)", WebkitBackdropFilter: "blur(20px)" }}>
        {/* Image */}
        <Image
          pos="absolute"
          src={thumbnail}
          alt={piece._id}
          style={{ objectFit: "contain", width: "100%", height: "100%" }}
        />
        {/* Overlay */}
        <ModalWorkshop architecture={piece.architecture} piece={piece} isOpen={modalOpen} onClose={e => { setModalOpen(false) }} />
        {isInterested && <PreviewOverlay piece={piece} isInterested={isInterested} mode={mode} source={source}
          onClick={onClick}
          onCreate={()=>{ onCreate?onCreate(piece):setModalOpen(true)}}
          onMoved={e => { onMoved(piece._id) }}
        />}
      </Box>
    </Box>
  );
}
