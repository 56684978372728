import { useEffect, useState } from "react"
import { getEnvVariable } from "utils/env"
import {
    Box, Heading, Image, Spinner, Tooltip, Button,
    Tabs, TabList, TabPanels, Tab, TabPanel, useToast,
    Checkbox, Wrap, Badge
} from "@chakra-ui/react"
import { formatDistanceToNow, parseISO } from 'date-fns'
import { useAuth } from "contexts/AuthContext"
import JsonView from "@uiw/react-json-view"
import { nordTheme } from "@uiw/react-json-view/nord"
import ModelFeed from "components/shared/Feed/ModelFeed"
import ModelButton from "components/Models/ModelButton"
import { generateStarterJob } from 'utils/pieceUtils'
import { ModalWorkshop } from 'components/Workshop/ModalWorkshop'

const ImageMetaView = ({ image }) => {
    const [data, setData] = useState(null)
    const REACT_APP_api_url = getEnvVariable("REACT_APP_api_url", process.env.REACT_APP_api_url)
    useEffect(() => {
        // Pass token in header
        fetch(`${REACT_APP_api_url}/v3/otherimages/${image}`)
        .then(response => response.json())
        .then(d => {
            if (d?.meta) {
                setData(d.meta)
            } else {
                setData({message:`No metadata found for image ${image}`})
            }
        })
        .catch(e => {
            console.error(e)
            setData({err: `Error fetching metadata for image ${image}:\n\n${e}`})
        })
    }, [image, REACT_APP_api_url, setData])
    return data ? <JsonView enableClipboard={false} value={data} style={nordTheme} displayObjectSize={false} displayDataTypes={false} shortenTextAfterLength={Infinity}/> : <Spinner />
}

export const ModelInfo = ({
    hash,
    onLoad,
    inModal = false,
    onRelatedSelect = (hash) => { console.log(`No onSelect set for ModelPreview for ${hash}`) } }) => {
    const toast = useToast()
    const { token, permissions } = useAuth()
    const [nsfw, setNsfw] = useState(false)
    const [featured, setFeatured] = useState(false)
    const [hidden, setHidden] = useState(false)
    const [trash, setTrash] = useState(false)
    const [data, setData] = useState(null)
    const [render, setRender] = useState(generateStarterJob(true))
    const [isFavorite, setIsFavorite] = useState(data ? data.isFavorite : false)
    const [isLoading, setIsLoading] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)
    const REACT_APP_api_url = getEnvVariable("REACT_APP_api_url", process.env.REACT_APP_api_url)
    const REACT_APP_model_images_url = getEnvVariable("REACT_APP_model_images_url", process.env.REACT_APP_model_images_url)
    const formatFileSize = (bytes) => {
        const units = ['B', 'KB', 'MB', 'GB', 'TB'];
        let index = 0;
        while (bytes >= 1024 && index < units.length - 1) {
          bytes /= 1024;
          index++;
        }
        return `${bytes.toLocaleString('en-US', { maximumFractionDigits: 2 })} ${units[index]}`;
      };
    useEffect(() => {
        const headers = token ? {'Content-Type': 'application/json', Authorization: `Bearer ${token}`} : {'Content-Type': 'application/json'};
        fetch(`${REACT_APP_api_url}/v3/modelhash/${hash}`, { headers })
            .then(response => response.json())
            .then(d => {
                setData(d)
                setIsFavorite(d.isFavorite)
                setNsfw(d.nsfw)
                setFeatured(d.featured)
                setHidden(d.hidden)
                setTrash(d.trash)
                onLoad && onLoad(d)
            })
    }, [hash, REACT_APP_api_url, setData, onLoad, token])

    const toggleFavorite = e => {
        let url = `${REACT_APP_api_url}/v3/${data.isFavorite ? "remove_model" : "add_model"}/${hash}`
        setIsLoading(true)
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
        }).then((response) => {
            return response.json()
        }).then(actualdata => {
            toast({
                title: `Model ${data.isFavorite ? "removed" : "added"}`,
                description: `Model ${data.isFavorite ? "removed from" : "added to"} your favorites.`,
                status: "success"
            })
            setIsFavorite(!isFavorite)
            setIsLoading(false)
        })
    }

    if (!data) return <Spinner />
    // Alternative to the carousel:
    const imageList = <>
        {data?.model_metadata?.modelVersions?.images?.map((image, index) => {
                let src = `${REACT_APP_model_images_url}/other-images/${image.url.split('/').pop()}`;
                if (src.indexOf('.jpeg') === -1) src += '.jpeg';
                return <Tabs isLazy>
                    <TabList>
                        <Tab>Image {index}</Tab>
                        <Tab>Metadata</Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            <Image src={src} />
                        </TabPanel>
                        <TabPanel>
                            {/* <Button>Try it</Button> */}
                            <ImageMetaView image={image.id} />
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            })}
    </>

    let tabs = [{
        "name": "Model Info",
        "content": <>
            <Image src={`${REACT_APP_model_images_url}/models/${data._id}.jpg`} />
            <div dangerouslySetInnerHTML={{ __html: data.description }} />
            <div dangerouslySetInnerHTML={{ __html: data.versionDescription }} />
        </>
    }, {
        "name": "Images",
        "content": imageList
    }]
    if (data.metadata) {
        tabs.push({
            "name": "Metadata",
            "content": <>
                {(data.metadata.ss_new_sd_model_hash) && <>
                    <Heading size={"sm"}>Base Model</Heading>
                    <ModelButton id={data.metadata.ss_new_sd_model_hash} />
                </>}
                {data.metadata.sd_merge_models && <Box rounded={"md"}>
                    <Heading size={"sm"}>Merged Models</Heading>
                    {/* Iterate over each object attribute */}
                    {Object.keys(data.metadata.sd_merge_models).map((key, index) => {
                        return <Box key={index}>
                            {/* <Heading size={"sm"}>{key}</Heading> */}
                            <ModelButton id={key} />
                        </Box>
                    })}
                </Box>}
                <JsonView enableClipboard={false} value={data.metadata} style={nordTheme} />
            </>
        })
    }
    if (data?.model_metadata && data?.relatedFileCount > 0) {
        tabs.push({
            "name": `Related Models (${data.relatedFileCount})`,
            "content": <ModelFeed onSelect={onRelatedSelect} filters={{ relatedTo: hash }} />
        })
    }

    return data ? (
        <Box>
            <Wrap mb={2}>
                <Tooltip hasArrow label={data._id}>
                    <Badge variant={"outline"} colorScheme={"blue"} style={{ cursor: "pointer" }} onClick={() => {
                        navigator.clipboard.writeText(data._id).then(
                            () => { toast({ title: `Model ID copied to clipboard.` }) }, () => { /* clipboard write failed */ }
                        );
                    }}>ID</Badge>
                </Tooltip>
                {<Badge variant={"outline"} colorScheme={data.match ? "green" : "red"}>{data.match ? "Online" : "Offline"}</Badge>}
                {<Badge variant={"outline"} colorScheme={"blue"}>{data.type}</Badge>}
                {<Badge variant={"outline"} colorScheme={"blue"}>{data.baseModel}</Badge>}
                {data.filesize && <Badge variant={"outline"} colorScheme={"blue"}>{formatFileSize(data.filesize)}</Badge>}
                {data?.datetime?.$date && <Badge variant={"outline"} colorScheme={"blue"}>{formatDistanceToNow(parseISO(data.datetime?.$date))} ago</Badge>}
            </Wrap>
            <ModalWorkshop architecture={render.architecture} piece={render} isOpen={modalOpen} onClose={e => { setModalOpen(false) }} />
            <Wrap mb={2}>
                <Tooltip hasArrow label={`${isFavorite ? "Remove from" : "Add to"} Favorites`}>
                    <Button isLoading={isLoading} colorScheme={isFavorite ? "red" : "green"} size={"sm"} onClick={toggleFavorite}>{isFavorite ? "Remove from" : "Add to"} Favorites</Button>
                </Tooltip>
                {data.type==="Checkpoint" && <Tooltip hasArrow label={`Try it out`}>
                    <Button isLoading={isLoading} colorScheme={"blue"} size={"sm"} onClick={e=>{
                        let newRender = generateStarterJob(true)
                        newRender.params.mainPass.model.sd_model_checkpoint = data._id
                        if(data.baseModel==="SDXL 1.0") newRender.params.mainPass.model.pipeline = {
                            "type": "sdxl-1.0",
                            "actual": {},
                            "custom": {}
                          }
                        setRender(newRender)
                        setModalOpen(true)
                    }}>Try it</Button>
                </Tooltip>}
            </Wrap>
            <Wrap>
                {/* NSFW Checkbox */}
                <Tooltip hasArrow label={"NSFW"}>
                    <Checkbox onChange={e => {
                        let nsfw = e.currentTarget.checked
                        setNsfw(nsfw)
                        let url = `${REACT_APP_api_url}/v3/${nsfw ? "mark_nsfw" : "mark_sfw"}/${hash}`
                        fetch(url, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${token}`,
                            }
                        }).then((response) => {
                            return response.json()
                        }).then(actualdata => {
                            toast({
                                title: `Model ${nsfw ? "marked as" : "unmarked as"} NSFW`,
                                description: `Model ${nsfw ? "marked as" : "unmarked as"} NSFW.`,
                                status: "success"
                            })
                        })

                    }} isChecked={nsfw} isDisabled={(permissions.includes("admin") || permissions.includes("moderator")) ? false : true}>NSFW</Checkbox>
                </Tooltip>
                {/* Featured Checkbox */}
                <Tooltip hasArrow label={"Featured"}>
                    <Checkbox onChange={e => {
                        let featured = e.currentTarget.checked
                        setFeatured(featured)
                        let url = `${REACT_APP_api_url}/v3/${featured ? "mark_featured" : "unmark_featured"}/${hash}`
                        fetch(url, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${token}`,
                            }
                        }).then((response) => {
                            return response.json()
                        }).then(actualdata => {
                            toast({
                                title: `Model ${featured ? "marked as" : "unmarked as"} Featured`,
                                description: `Model ${featured ? "marked as" : "unmarked as"} Featured.`,
                                status: "success"
                            })
                        })

                    }} isChecked={featured} isDisabled={(permissions.includes("admin") || permissions.includes("moderator")) ? false : true}>Featured</Checkbox>
                </Tooltip>
                {/* Trash Checkbox */}
                <Tooltip hasArrow label={"Trash"}>
                    <Checkbox onChange={e => {
                        let v = e.currentTarget.checked
                        setTrash(v)
                        let url = `${REACT_APP_api_url}/v3/${v ? "mark_trash" : "unmark_trash"}/${hash}`
                        fetch(url, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${token}`,
                            }
                        }).then((response) => {
                            return response.json()
                        }).then(actualdata => {
                            toast({
                                title: `Model ${v ? "marked as" : "unmarked as"} trash.`,
                                description: `Model ${v ? "marked as" : "unmarked as"} trash.`,
                                status: "success"
                            })
                        })

                    }} isChecked={trash} isDisabled={(permissions.includes("admin") || permissions.includes("moderator")) ? false : true}>Trash</Checkbox>
                </Tooltip>
                {/* Hidden Checkbox */}
                {permissions.includes("hidden_models") && <Tooltip hasArrow label={"Hidden"}>
                    <Checkbox onChange={e => {
                        let v = e.currentTarget.checked
                        setHidden(v)
                        let url = `${REACT_APP_api_url}/v3/${v ? "mark_hidden" : "unmark_hidden"}/${hash}`
                        fetch(url, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${token}`,
                            }
                        }).then((response) => {
                            return response.json()
                        }).then(actualdata => {
                            toast({
                                title: `Model ${v ? "marked as" : "unmarked as"} hidden.`,
                                description: `Model ${v ? "marked as" : "unmarked as"} hidden.`,
                                status: "success"
                            })
                        })

                    }} isChecked={hidden} isDisabled={(permissions.includes("hidden") || permissions.includes("hidden_models")) ? false : true}>Hidden</Checkbox>
                </Tooltip>}
            </Wrap>
            {data?.model_metadata?.tags && <Wrap mt={2}>
                {data.model_metadata.tags.map((tag, index) => <Badge key={index} variant={"outline"} colorScheme={"blue"}>{tag}</Badge>)}
            </Wrap>}
            {!inModal && <Heading size={"md"}>{data.name} - {data.model_metadata.version}</Heading>}
            <Tabs lazyBehavior="unmount" isLazy>
                <TabList>
                    {tabs.map((tab, index) => <Tab key={index}>{tab.name}</Tab>)}
                </TabList>
                <TabPanels>
                    {tabs.map((tab, index) => <TabPanel key={index}>{tab.content}</TabPanel>)}
                </TabPanels>
            </Tabs>
        </Box>
    ) : <Spinner />
}