import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useAuth } from 'contexts/AuthContext'
import { Spinner, Center, Box, Image } from '@chakra-ui/react'
// import { useNavigate} from 'react-router-dom'

import { getEnvVariable } from 'utils/env'

function UploadFeed({ }) {
  const { isAuthenticated, token } = useAuth()
  const [items, setItems] = useState([])
  const [lastItem, setLastItem] = useState(null)
  const sentinelRef = useRef(null)
  
  const [fetching, setFetching] = useState(false)
  const [hasMore, setHasMore] = useState(true)

  const REACT_APP_api_url = getEnvVariable("REACT_APP_api_url", process.env.REACT_APP_api_url)
  const path = "/my/uploads"
  const lastPiece = items[items.length - 1]

  let apiURL = `${REACT_APP_api_url}${path}`
  
  if (lastPiece) {
    apiURL = `${REACT_APP_api_url}${path}/50/${lastPiece._id}`
  } else {
    apiURL = `${REACT_APP_api_url}${path}/50`
  }

  const fetchNewItems = useCallback(function () {
    let headers = {
      "Content-Type": "application/json"
    }
    if (isAuthenticated) {
      headers = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    }
    return fetch(apiURL, { method: "POST", headers })
      .then(response => response.json())
      .then(data => data)
  }, [apiURL, isAuthenticated, token])

  useEffect(() => {
    setItems([])
    setHasMore(true)
  }, [])

  const fetchMoreItems = useCallback(() => {
    setFetching(true)
    return fetchNewItems().then(newItems => {
      if (newItems.length === 0) {
        setHasMore(false)
      } else {
        setItems(prevItems => [...prevItems, ...newItems.filter(newItem => !prevItems.some(prevItem => prevItem._id === newItem._id))])
        setLastItem(newItems[newItems.length - 1])
      }
      setFetching(false)
    }).catch(err => {
      console.log(err)
      setFetching(false)
    })
  }, [fetchNewItems])

  useEffect(() => {
    const sr = sentinelRef.current
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.target === sentinelRef.current && entry.isIntersecting && !fetching && hasMore) {
          fetchMoreItems().then(() => { })
        }
      })
    })
    observer.observe(sentinelRef.current)
    return () => observer.unobserve(sr)
  }, [fetching, hasMore, fetchMoreItems])

  return (
    <>
      <Box>
        {items.map(piece => {
          // let pieceClass = "square"
          return <div
            style={{ height: `250px` }}
            data-id={piece._id} key={piece._id}>
            <Image />
            {/* {!activeImages[piece._id] && <Box pos="relative" borderRadius="lg" bg={"blue.800"} />} */}
          </div>
        })}
      </Box>
      <Box ref={sentinelRef} style={{ width: "100%" }}>
          <Center p={5}>{fetching && <Spinner label='Loading more...' />}</Center>
          {!hasMore && <Center p={5}>No more items</Center>}
        </Box>
    </>
  )
}

export default UploadFeed
