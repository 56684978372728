import {
    Box, Switch, FormLabel, Tooltip, Flex
} from '@chakra-ui/react';

import { LoraOptions } from './LoraOptions';
import { PropBox } from './PropBox';
import ModelButton from 'components/Models/ModelButton';

/**
 * Renders the FluxModelOptions component.
 * 
 * @param {Object} props - The component props.
 * @param {Object} props.value - The current value of the FluxModelOptions.
 * @param {Function} props.onChange - The function to handle value changes.
 * @returns {JSX.Element} The rendered FluxModelOptions component.
 */
export const FluxModelOptions = ({ value, onChange }) => {

    return value ? <PropBox type="flux_model_options" label="Model Options" value={value} onChange={v => onChange && onChange(v)}>
        <Flex>
            <Tooltip hasArrow label="Each model has been trained or fine-tuned on different sets of training data.  This can give each model significantly different aesthetic looks.  Press the model details button next to the dropdown to view additional details about your selected model." openDelay={250} >
                <FormLabel>Model</FormLabel>
            </Tooltip>
            <Flex w={"full"}>
                <Tooltip hasArrow label="Browse Models" openDelay={250}>
                    <ModelButton modelArchitecture={"flux"} onChange={v=>{onChange && onChange({...value, unet:v})}} id={value.unet}/>
                </Tooltip>
            </Flex>
        </Flex>
        <Flex>
            <Tooltip hasArrow label="Enable LORAs" openDelay={250}>
                <FormLabel htmlFor="loras_enabled">Enable LORAs</FormLabel>
            </Tooltip>
            <Switch
                size={"sm"}
                id="loras_enabled"
                isChecked={value.loras_enabled}
                onChange={e => onChange && onChange({ ...value, "loras_enabled": e.target.checked })}
            />
        </Flex>
        {value.loras_enabled && <LoraOptions modeltype="lora" value={value.loras} pipeline={{type:"flux"}} onChange={loras => { onChange && onChange({ ...value, loras }) }} />}
        {/* <LoraOptions modeltype="embedding" value={value.embeddings} pipeline={value.pipeline} onChange={embeddings => { onChange && onChange({ ...value, embeddings }) }} /> */}
    </PropBox> : <Box>Loading...</Box>
}