import { ChakraProvider, Flex } from "@chakra-ui/react"
import { PresetsProvider } from "contexts/PresetsContext"
import { SiteRouter } from "./components/shared/SiteRouter"
import "./App.css"

function App() {
  return <ChakraProvider>
    <Flex direction="column" h="100vh" className="App">
      <PresetsProvider>
        <SiteRouter />
      </PresetsProvider>
    </Flex>
  </ChakraProvider>
}

export default App
