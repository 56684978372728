import { getEnvVariable } from 'utils/env';
import { calculateBlobSHA256, defaultImg2Img } from 'utils/pieceUtils';

const REACT_APP_api_url = getEnvVariable("REACT_APP_api_url", process.env.REACT_APP_api_url)

export function conformProperty(defaultObj, newObj) {
  for (let key in defaultObj) {
    if (defaultObj.hasOwnProperty(key)) {
      if (typeof defaultObj[key] === 'object') {
        // If the property is an array, conform each object in newObj array
        // to the structure of the example entry in defaultObj array
        if (Array.isArray(defaultObj[key]) && defaultObj[key].length > 0) {
          let exampleEntry = defaultObj[key][0];
          if (!newObj[key]) {
            newObj[key] = [];
          }
          for (let i = 0; i < newObj[key].length; i++) {
            if (typeof newObj[key][i] !== 'object') {
              // If it's not an object, set it to the exampleEntry 
              // This assumes that arrays contain objects. Adjust if different types can be inside
              newObj[key][i] = JSON.parse(JSON.stringify(exampleEntry));
            } else {
              conformProperty(exampleEntry, newObj[key][i]);
            }
          }
        }
        // If the property is an object, recursively conform nested properties
        else {
          if (!newObj[key]) {
            console.log(`Object '${key}' not found.`)
            newObj[key] = {};  
          }
          conformProperty(defaultObj[key], newObj[key]);
        }
      } else {
        if (!newObj.hasOwnProperty(key)) {
          newObj[key] = defaultObj[key];
        }
      }
    }
  }
}

export function conformJob(data) {
  let defaults = generateStarterJob(true).params
  if(!data) data = {}
  data.params = data.params || {}
  for (let key in defaults) {
    if (data && data.params && !(key in data.params)) {
      data.params[key] = JSON.parse(JSON.stringify(defaults[key]));
    } else {
      if (defaults[key] !== undefined) conformProperty(defaults[key], data.params[key])
    }
  }
  return data
}

export async function compile(j, options) {
  let { batchSize, priority, toast, onComplete, token, isPrivate, debug } = options
  if (isPrivate === undefined) isPrivate = false
  let headers = {
    Authorization: `Bearer ${token}`
  }
  let params = j.params
  j.architecture = "flux"
  j.private = isPrivate
  j.batch_size = batchSize
  j.priority = priority
  
  j.params["debug"] = debug
  
  let imageOperations = []
  // img2img Image
  if(params?.mainPass?.img2img?.enabled && params?.mainPass?.img2img?.image?.content){
    imageOperations.push({
      content: params.mainPass.img2img.image.content,
      hash: params.mainPass.img2img.image.hash
    })
    // delete params.mainPass.img2img.image.content
  }
  // // img2img Mask
  // if(params.mainPass.img2img.enabled && params.mainPass.img2img.inpaint.enabled && params.mainPass.img2img.inpaint.mask.content){
  //   imageOperations.push({
  //     content: params.mainPass.img2img.inpaint.mask.content,
  //     hash: params.mainPass.img2img.inpaint.mask.hash
  //   })
  //   // delete params.mainPass.img2img.inpaint.mask.content
  // }
  
  let imageOperationsPromises = imageOperations.map(async image=>{
    let hash = image.hash
    if(image.content instanceof Blob){
      hash = await calculateBlobSHA256(image.content)
    }
    // console.log(`Hash: ${hash}`)
    const metadata = await fetch(`${REACT_APP_api_url}/v3/getimagehash/${hash}`).then(response => {
      return response.json()
    })
    if (metadata === null) {
      toast({
        title: "Uploading new image",
        description: `Hash : ${hash}`
      })
      const formData = new FormData();
      formData.append("file", image.content, hash);
      await fetch(`${REACT_APP_api_url}/v3/saveimagehash/${hash}`, {
        method: "POST",
        headers,
        body: formData
      }).then(response => {
        toast({
          title: "New image uploaded",
        })
        return response.json()
      }).then(data => {
        console.log(data)
      })
    }else{
      toast({
        title: "Image already uploaded"
      })
      console.log(metadata)
    }
  })
  await Promise.all(imageOperationsPromises)
  
  headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`
  }

  async function afterLayerOperations() {
    const { success: mutateSuccess } = await fetch(
      `${REACT_APP_api_url}/v3/create/flux`,
      {
        method: 'POST',
        headers,
        body: JSON.stringify({ job: j }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        toast({
          title: "Job Received"
        });
        onComplete && onComplete();
        // onCloseHandler();
        return data;
      });

    if (mutateSuccess) {
      // Handle the success case
    }
  }
  afterLayerOperations()
  // Promise.all([]).then(afterLayerOperations);

}
// Main Pass
let mainPass = {
  // img2img: defaultImg2Img(),
  img2img: defaultImg2Img(),
  prompts: {
    positive: "A beautiful landscape",
    negative: ""
  },
  width: 1024,
  height: 1024,
  clip_guidance: 3.5,
  model: {
    unet: "4610115bb0c89560703c892c59ac2742fa821e60ef5871b33493ba544683abd7",
    vae: "afc8e28272cd15db3919bacdb6918ce9c1ed22e96cb12c4d5ed0fba823529e38",
    loras_enabled: false,
    ti_enabled: false,
    loras: [],
    locons: [],
    embeddings: [],
    // clip_skip: 1
  },
  sampler: {
    cfg: 1.0,
    max_shift : 1.15,
    base_shift : 0.50,
    seed: -1,
    steps: 20,
    refiner_steps: 5,
    detail_level: 1.0,
    sampler_name: "euler",
    scheduler: "normal",
    denoise: 1.0
  }
}

// Upscaler
let upscaler = {
  enabled: false,
  method: "Latent",
  scale: 2,
  use_main_sampler: true,
  use_main_model_pipeline: true,
  use_main_prompts: true,
  prompts: {
    positive: "highly detailed scene",
    negative: "blurry"
  },
  model: {
    pipeline: {
      type: "sd-1.5",
      actual: {},
      custom: {}
    },
    sd_model_checkpoint: "cc6cb27103417325ff94f52b7a5d2dde45a7515b25c255d8e396c90014281516",
    loras_enabled: false,
    locons_enabled: false,
    ti_enabled: false,
    freeU : {
      enabled : false,
      b1 : 1.10,
      b2 : 1.20,
      s1 : 0.90,
      s2 : 0.20,
      target_block : "output_block",
      multiscale_mode : "Default",
      multiscale_strength : 1.0,
      slice_b1 : 640,
      slice_b2 : 320,
      b1_mode : "bislerp",
      b2_mode : "bislerp",
      b1_blend : 1.0,
      b2_blend : 1.0,
      threshold : 1,
      use_override_scales : "false",
      override_scales : ""
    },
    loras: [],
    locons: [],
    embeddings: [],
    clip_skip: 1
  },
  sampler: {
    cfg: 7.0,
    seed: -1,
    steps: 25,
    refiner_steps: 5,
    detail_level: 1.0,
    sampler_name: "euler_ancestral",
    scheduler: "normal",
    denoise: 0.4
  }
}

// Face Detailer
let faceDetailer = {
  enabled: false,
  detection_model: "face_yolov8n.pt",
  prompts: {
    positive: "highly detailed face",
    negative: "ugly"
  },
  threshold: 0.3,
  dilation: 32,
  mask_blur: 5,
  crop_factor: 3.0,
  use_main_sampler: true,
  use_main_model_pipeline: true,
  use_main_prompts: false,
  model: {
    pipeline: {
      type: "sd-1.5",
      actual: {},
      custom: {}
    },
    sd_model_checkpoint: "cc6cb27103417325ff94f52b7a5d2dde45a7515b25c255d8e396c90014281516",
    loras_enabled: false,
    locons_enabled: false,
    ti_enabled: false,
    freeU : {
      enabled : false,
      b1 : 1.10,
      b2 : 1.20,
      s1 : 0.90,
      s2 : 0.20,
      target_block : "output_block",
      multiscale_mode : "Default",
      multiscale_strength : 1.0,
      slice_b1 : 640,
      slice_b2 : 320,
      b1_mode : "bislerp",
      b2_mode : "bislerp",
      b1_blend : 1.0,
      b2_blend : 1.0,
      threshold : 1,
      use_override_scales : "false",
      override_scales : ""
    },
    loras: [],
    locons: [],
    embeddings: [],
    clip_skip: 1
  },
  sampler: {
    cfg: 7.0,
    seed: -1,
    steps: 25,
    refiner_steps: 5,
    detail_level: 1.0,
    sampler_name: "euler_ancestral",
    scheduler: "normal",
    denoise: 0.4
  }
}

// Post Processing
let postProcessing = {
  skin_enhancement: false,
  skin_enhancement_strength: 0.9,
  texturize: false,
  texturize_strength: 0.9
}

export function generateStarterJob({ privatesettings }) {
  return {
    status: "new",
    architecture: "flux",
    nsfw: false,
    private: privatesettings ? true : false,
    hide: false,
    review: true,
    priority: "medium",
    location: null,
    params: {
      // Main Pass
      mainPass,
      // Upscaler
      // upscaler,
      // Face Detailer
      // faceDetailer,
      // Post-processing
      postProcessing
    }
  }
}

// Maybe
export function generateFluxModelList(options) {
  let headers = {
    'Content-Type': 'application/json'
  }
  if (options && options.token) {
    headers["Authorization"] = `Bearer ${options.token}`
  }
  return fetch(`${REACT_APP_api_url}/v3/modellist`, {
    method: 'POST',
    headers: {
      ...headers,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(options.pipeline)
  }).then((response) => {
    return response.json()
  }).then(modelList => {
    let models = []
    let model_hashes = {}
    modelList.forEach(model => {
      if (!model_hashes[model.hash]) {
        models.push(model)
        model_hashes[model.hash] = true
      }
    })
    return models
  })
}