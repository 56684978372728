import React, { createContext, useContext, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
  const [token, setToken] = useState(null);
  const [permissions, setPermissions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getTokenAndPermissions = async () => {
        if (getAccessTokenSilently !== undefined && isAuthenticated) {
            console.log(`🎉 Authenticated.  Welcome, ${user.name} Getting token and permissions...`)
            // console.log(user)
            try {
                setIsLoading(true);
                const newToken = await getAccessTokenSilently();
                // console.log(newToken)
                const decodedToken = JSON.parse(atob(newToken.split('.')[1]));
                const newPermissions = decodedToken.permissions || [];
                setToken(newToken);
                setPermissions(newPermissions);
            } catch (e) {
                console.error(e);
            } finally {
                setIsLoading(false);
            }
        }else{
            console.log("Not Authenticated.  Welcome, guest.")
            setIsLoading(false);
        }
    };
    getTokenAndPermissions();
  }, [isAuthenticated, getAccessTokenSilently, user]);

  return (
    <AuthContext.Provider value={{ isAuthenticated, token, user, permissions, isLoading }}>
        {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
