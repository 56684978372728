import { Box, Tooltip, Tabs, TabList, TabPanels, Tab, TabPanel, Icon, Flex } from '@chakra-ui/react'
import { FaceDetailerOptions } from './FaceDetailerOptions';
import { RoopOptions } from './RoopOptions';
import { FluxMainPassOptions } from './FluxMainPassOptions';
import { WorkshopPropertiesToolbar } from './WorkshopPropertiesToolbar';
import { ControlNetOptions } from './ControlNetOptions';
import { IPAdapterOptions } from './IPAdapterOptions';
import { Img2ImgOptions } from './Img2ImgOptions';
import { UpscaleOptions } from './UpscaleOptions';
import { FaceRestoreOptions } from './FaceRestoreOptions';
import { PostProcessingOptions } from './PostProcessingOptions';

// Icons
import { LuScanFace } from 'react-icons/lu'
import { IoGameControllerOutline, IoApertureOutline } from 'react-icons/io5'
import { CgOptions } from 'react-icons/cg'
import { ImImages } from 'react-icons/im'
import { TbArrowsMaximize } from 'react-icons/tb'
import { PropBox } from './PropBox';
import { generateFluxStarterJob } from 'utils/fluxUtils';
import { GiProcessor } from "react-icons/gi";
import { json } from 'react-router-dom';

/**
 * Renders the FluxProperties component.
 *
 * @param {boolean} noTooltips - Flag indicating whether tooltips are disabled.
 * @param {Object} data - The data object.
 * @param {Object} defaults - The default values object.
 * @param {boolean} isLoaded - Flag indicating whether the component is loaded.
 * @param {boolean} isGenerating - Flag indicating whether the component is generating.
 * @param {Function} onChange - The onChange event handler.
 * @param {Function} generateHandler - The generateHandler event handler.
 * @param {Function} rollHandler - The rollHandler event handler.
 * @returns {JSX.Element} The rendered WorkshopProperties component.
 */
export function FluxProperties({
  architecture = "flux",
  data,
  isLoaded = false,
  isGenerating = false,
  onChange = () => { console.warn("No onChange handler for FluxProperties.") },
  generateHandler = () => { console.warn("No generateHandler handler for FluxProperties.") },
  rollHandler = () => { console.warn("No rollHandler handler for FluxProperties.") },
}) {

  return (
    <PropBox h={"100%"} collapsible={false} type="piece" value={data.params} label="Properties"
      onChange={(params) => onChange({ ...data, params })}
      isLoaded={isLoaded}
    >
      <Flex direction="column" w={"full"} h={"100%"}>
        <Box flexShrink={0}>
          {/* Toolbar */}
          <WorkshopPropertiesToolbar
            architecture={architecture}
            isLoaded={isLoaded}
            data={data}
            onRoll={rollHandler}
            generateHandler={generateHandler}
            isGenerating={isGenerating}
            onChange={(d) => {
              onChange(d);
            }}
          />
        </Box>
        <Box overflowY={'auto'} height={"100%"}>
          {/* Vertical Properties Tabs */}
          <Tabs h={"100%"} orientation="vertical" isLazy lazyBehavior={'unmount'}>
            <Flex h={"100%"} w={"full"} direction={"row"}>
              <TabList>
                {/* main pass */}
                <Tab>
                  <Tooltip
                    placement="right"
                    label="General"
                    hasArrow
                  >
                    <Icon boxSize={8} children={<CgOptions />} />
                  </Tooltip>
                </Tab>
                {/* img2img */}
                {data.params.mainPass && data.params.mainPass.img2img && data.params.mainPass.img2img.enabled && (
                  <Tab>
                    <Tooltip
                      placement="right"
                      label="img2img Options"
                      hasArrow
                    >
                      <Icon boxSize={8} children={<ImImages />} />
                    </Tooltip>
                  </Tab>
                )}
                {/* post-processing */}
                <Tab>
                  <Tooltip
                    placement="right"
                    label="Post-processing"
                    hasArrow
                  >
                    <Icon boxSize={8} children={<GiProcessor />} />
                  </Tooltip>
                </Tab>
              </TabList>
              <TabPanels h={'100%'}>
                {/* General */}
                <TabPanel p={0} h={'100%'}>
                  <FluxMainPassOptions 
                    value={data.params.mainPass} 
                    architecture={architecture}
                    onChange={(v) => { onChange({ ...data, params: { ...data.params, mainPass: v }, }); }} />
                </TabPanel>
                {/* img2img */}
                {data?.params?.mainPass?.img2img?.enabled && <TabPanel p={0}>
                  <Img2ImgOptions
                    includeInpaint={false}
                    denoise={data.params.mainPass.sampler.denoise}
                    value={data.params.mainPass.img2img}
                    onChange={(v) => {
                      onChange({
                        ...data,
                        params: {
                          ...data.params,
                          mainPass: {
                            ...data.params.mainPass,
                            img2img: v,
                          },
                        },
                      });
                    }}
                    onDenoiseChange={(v) => {
                      onChange({
                        ...data,
                        params: {
                          ...data.params,
                          mainPass: {
                            ...data.params.mainPass,
                            sampler: {
                              ...data.params.mainPass.sampler,
                              denoise: v,
                            },
                          },
                        },
                      });
                    }}
                  />
                </TabPanel>}
                {/* Post-processing */}
                <TabPanel p={0}>
                  <Flex direction={"column"} gap={2}>
                    <PostProcessingOptions value={data.params.postProcessing} onChange={v => {
                      onChange({
                        ...data,
                        params: {
                          ...data.params, postProcessing: v
                        }
                      })
                    }}
                    />
                  </Flex>
                </TabPanel>
              </TabPanels>
            </Flex>
          </Tabs>
        </Box>
      </Flex>
      {/* <span>{JSON.stringify(data.params)}</span> */}
    </PropBox>
  );
}