import React from 'react';
import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import InfinityFeed from 'components/shared/Feed/InfinityFeed';
import UploadFeed from 'components/shared/Feed/UploadFeed';
import Albums from 'components/Albums/Albums';
import {
  Avatar,
  Flex,
  Heading,
  HStack,
  VStack,
  Button,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  RadioGroup,
  Radio,
  Stack,
  Icon
} from '@chakra-ui/react';
import { SocialButton } from 'components/shared/SocialButton';
import { FaTwitter } from 'react-icons/fa';
import { useAuth } from 'contexts/AuthContext';
import { getEnvVariable } from 'utils/env';
import { AiOutlineStar, AiOutlineHeart } from 'react-icons/ai';
import { HiOutlineFire } from 'react-icons/hi';
import { FaUserFriends, FaRegTrashAlt } from "react-icons/fa";
import { BiHide } from 'react-icons/bi';
import { PiPaintBrushLight } from 'react-icons/pi';
import { FaCloudUploadAlt } from "react-icons/fa";

export default function UserGalleryPage() {
  const { token, user, permissions } = useAuth()
  const [browseDirection, setBrowseDirection] = useState("desc");
  const [userDetails, setUserDetails] = useState(null);
  const [userIsLoading, setUserIsLoading] = useState(false);

  const params = useParams()
  const type = params.type
  const navigate = useNavigate()

  const REACT_APP_api_url = getEnvVariable("REACT_APP_api_url", process.env.REACT_APP_api_url)

  useEffect(() => {

  }, [browseDirection])

  useEffect(() => {
    setUserIsLoading(true);
    let userInfoUrl = `${REACT_APP_api_url}/user/${params.user_id}`
    fetch(userInfoUrl)
      .then((response) => response.json())
      .then((actualData) => {
        setUserIsLoading(false);
        setUserDetails(actualData);
      })
      .catch(err => {
        console.warn(`Cannot access ${userInfoUrl}`)
      });
  }, [params.user_id, params.page, REACT_APP_api_url]);

  const handleFollowClick = () => {
    fetch(`${REACT_APP_api_url}/follow/${params.user_id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  };

  let userAvatar = '/avatar-placeholder.png';
  let userNameGallery = "Loading..."
  if (!userIsLoading && userDetails) {
    userAvatar = userDetails.picture ? userDetails.picture : userDetails.avatar
    userNameGallery = userDetails.nickname ? userDetails.nickname : userDetails.user_name
  }
  let order = `/${browseDirection}`
  let tabList = [
    {
      key: "recent",
      icon: <Icon as={HiOutlineFire} mr={2} />,
      text: "Published",
      tab: <TabPanel key="recent"><InfinityFeed path={`/v3/userfeed/${params.user_id}/recent${order}`} method="continuation"/></TabPanel>
    },
    {
      key: "popular",
      icon: <Icon as={AiOutlineStar} mr={2} />,
      text: "Popular",
      tab: <TabPanel key="popular"><InfinityFeed path={`/v3/userfeed/${params.user_id}/popular${order}`} method="continuation"/></TabPanel>
    }
  ]
  if (permissions && (permissions.includes('admin') || (user && user.sub === params.user_id))) tabList.push({
    key: "following",
    text: "Following",
    icon: <Icon as={FaUserFriends} mr={2} />,
    tab: <TabPanel key="following"><InfinityFeed path={`/following/${params.user_id}${order}`} method="continuation"/></TabPanel>
  })
  if (permissions && (permissions.includes('admin') || (user && user.sub === params.user_id))) tabList.push({
    key: "favorites",
    text: "Favorites",
    icon : <Icon as={AiOutlineHeart} mr={2}/>,
    tab: <TabPanel key="favorites"><InfinityFeed page={params.page} path={`/v3/myfavs/${params.user_id}${order}`} method="continuation"/></TabPanel>
  })
  if (permissions && (permissions.includes('admin') || (user && user.sub === params.user_id))) tabList.push({
    key: "personal",
    text: "Personal",
    icon: <Icon as={BiHide} mr={2} />,
    tab: <TabPanel key="personal"><InfinityFeed page={params.page} path={`/v3/my/personal_pieces/${params.user_id}${order}`} method="continuation" /></TabPanel>
  })
  if (permissions && (permissions.includes('admin') || (user && user.sub === params.user_id))) tabList.push({
    key: "workspace",
    text: "Workspace",
    icon: <Icon as={PiPaintBrushLight} mr={2} />,
    tab: <TabPanel key="workspace"><InfinityFeed page={params.page} path={`/v3/my/reviews/${params.user_id}${order}`} mode="review" source="workspace" method="continuation" /></TabPanel>
  })
  if (permissions && permissions.includes('admin')) tabList.push({
    key: "deleted",
    text: "Deleted",
    icon: <Icon as={FaRegTrashAlt} mr={2} />,
    tab: <TabPanel key="deleted"><InfinityFeed page={params.page} path={`/v3/my/deleted_pieces/${params.user_id}${order}`} mode="deleted" source="deleted" method="continuation" /></TabPanel>
  })
  if (permissions && (permissions.includes('admin') || (user && user.sub === params.user_id))) tabList.push({
    key: "uploads",
    text: "Uploads",
    icon: <Icon as={FaCloudUploadAlt} mr={2} />,
    tab: <TabPanel key="uploads"><UploadFeed /></TabPanel>
  })

  let si = 0
  tabList.forEach((tab, index) => { if (tab.key === type) si = index })

  return (
    <Flex direction="column" w={'full'} h={'100%'}>
      <HStack flexShrink={0} pl={5}>
        {userDetails && <Avatar size="xl" src={userAvatar} />}
        <VStack alignItems={'left'}>
          <Heading>{`${userNameGallery}'s Gallery`}</Heading>
          {userDetails && (
            <HStack>
              <Button
                colorScheme="blue"
                variant="outline"
                size="xs"
                onClick={handleFollowClick}
              >
                Follow
              </Button>
              {userDetails.social && userDetails.social.twitter && (
                <SocialButton
                  label={userDetails.social.twitter}
                  href={`https://twitter.com/${userDetails.social.twitter}`}
                >
                  <FaTwitter />
                </SocialButton>
              )}
            </HStack>
          )}
        </VStack>
      </HStack>
      <RadioGroup
        pl={5}
        onChange={(v) => {
          setBrowseDirection(v);
        }}
        value={browseDirection}
      >
        <Stack direction="row">
          <Radio value="asc">Ascending</Radio>
          <Radio value="desc">Descending</Radio>
          <Radio value="random">Random</Radio>
        </Stack>
      </RadioGroup>
      <Tabs
        // variant={"solid-rounded"}
        variant={"enclosed"}
        isLazy
        lazyBehavior="unmount"
        index={si}
        onChange={(index) => {
          navigate(`/gallery/${params.user_id}/${tabList[index].key}`);
        }}
      >
        <TabList overflowX="auto" overflowY="hidden">{tabList.map(tab => <Tab key={tab.key} isDisabled={tab.disabled === true ? true : false}>{tab.icon && tab.icon}{tab.text}</Tab>)}</TabList>
        <TabPanels>{tabList.map(tab => tab.tab)}</TabPanels>
      </Tabs>
    </Flex>
  );
}
